import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import { Container, useMediaQuery } from '@material-ui/core';

import { GeneralHero } from '../components/General/GeneralHero';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { Resources } from '../components/General/Resources';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { SectionWaveDefault } from '../components/Waves/SectionWaveDefault';
import { SEO } from '../components/SEO';
import { ProductOverview } from '../components/ProductFeature/ProductOverview';
import { PricingBody } from '../components/EmeaPricing/PricingBody';
import { SectionLight } from '../components/Sections/SectionLight';
import { PricingFeature } from '../components/Pricing/PricingFeature';

import LocaleContext from '../context/LocaleContext';
import Form from '../components/ContactForm/Form';

const PricingPage = ({ data: { pricing }, location }) => {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const {
    _rawCustomerLogosHeader,
    customerLogos,
    testimonialHeader,
    testimonials,
    resourceTitle,
    resources,
    _rawResourceBody,
    hero,
    contactForm,
    pardotUrl,
    _rawOverviewContent,
    overviewImage,
    metaTitle,
    metaDescription,
    emeaPricing,
    featureSection,
  } = pricing;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  const md = useMediaQuery('(max-width: 960px)');
  const video = testimonials.length ? testimonials[0]?.videoVariant : null;
  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />

      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      {emeaPricing ? (
        <SectionWhite>
          <PricingBody
            softwareFeatures={emeaPricing.softwareFeatures}
            intro={_rawOverviewContent}
            pricingCards={emeaPricing.pricingCards}
          />
        </SectionWhite>
      ) : (
        <SectionWhite>
          <Container>
            <Form
              pardotUrl={pardotUrl}
              contactForm={contactForm}
              location={location}
              slideUp
            />
            {featureSection.length ? (
              <PricingFeature feature={featureSection[0]} />
            ) : null}
          </Container>
        </SectionWhite>
      )}

      <SectionBlue
        extraTop={!video}
        top={video}
        additionalTop={video && md ? 60 : video ? 80 : null}
        marginBottom='-220px'
      >
        <SectionWaveDefault top='0px' fillColorShape='#FFF' />
        <Testimonials
          testimonials={testimonials}
          header={testimonialHeader}
          background
        />
      </SectionBlue>
      {customerLogos && (
        <SectionWhite top additionalTop={240} extraBottom={emeaPricing}>
          <CustomerLogos
            customerLogosArray={customerLogos}
            customerLogosHeader={_rawCustomerLogosHeader}
          />
          {emeaPricing && (
            <SectionWaveDefault
              flipHorizontal={false}
              flipVertical={true}
              bottom='0px'
              fillColorShape='#F5F9FF'
              fillColorBorder='#F5F9FF'
            />
          )}
        </SectionWhite>
      )}
      {emeaPricing && (
        <SectionLight>
          <Form
            pardotUrl={pardotUrl}
            contactForm={contactForm}
            location={location}
          />
        </SectionLight>
      )}
      <SectionWhite top zIndex={3} extraTop={emeaPricing}>
        {emeaPricing && (
          <SectionWaveDefault
            top='0px'
            fillColorBorder='#F5F9FF'
            flipHorizontal={true}
          />
        )}

        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>
    </>
  );
};

export default PricingPage;

export const query = graphql`
  query ($slug: String!) {
    pricing: sanityPricing(slug: { current: { eq: $slug } }) {
      metaTitle
      metaDescription
      _type
      id
      slug {
        current
      }
      language
      hero {
        softwareLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        showEmailForm
      }
      _rawOverviewContent
      overviewImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      emeaPricing {
        pricingCards {
          icon
          accentColor {
            hexValue
          }
          title
          discount
          header
          content
        }
        softwareFeatures {
          _id
          title
          comparisonHeader
          featureListCta
          logos {
            asset {
              gatsbyImageData(placeholder: BLURRED, height: 85)
            }
          }
          alternateTextHeaders
          features {
            featureTitle
            featureDescription
            colOne
            colTwo
            colThree
          }
          expandText
          hideText
          expandedListHeader
          expandedListSubheader
        }
      }
      featureSection {
        _key
        title
        cardTitle
        cardFaIcon
        cardIconColor {
          hexValue
        }
        bodySubHeader
        bodyHeader
        _rawAlternativeBodyHeader
        image {
          featureImage {
            asset {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
        videoVariant
        androidLink
        iosLink
        qbLink
        thumbnailVideoUrl
        ctaText
        ctaLink
        _rawBodyCopy
        internalLink
      }
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        title
      }
      testimonialHeader
      testimonials {
        title
        testimonialText
        nameAndTitle
        company
        videoVariant
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
    }
  }
`;
