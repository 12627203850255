import React from 'react';

import { Grid } from '@material-ui/core';

import { Container, makeStyles } from '@material-ui/core';
import { SoftwareFeatures } from './SoftwareFeatures';
import { PricingIntro } from './PricingIntro';
import { PricingLongCard } from './PricingLongCard';

const useStyles = makeStyles((theme) => ({
  cards: {
    padding: '2rem 0 4rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0',
    },
  },
}));

export const PricingBody = ({ softwareFeatures, intro, pricingCards }) => {
  const classes = useStyles();
  return (
    <>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <PricingIntro intro={intro} />

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          className={classes.cards}
          spacing={4}
        >
          {pricingCards.map((card, index) => (
            <PricingLongCard
              card={card}
              key={index}
              index={index}
              softwareFeatures={softwareFeatures}
            />
          ))}
        </Grid>
        <SoftwareFeatures softwareFeatures={softwareFeatures} />
      </Container>
    </>
  );
};
