import React, { useState } from 'react';

import { Collapse } from '@material-ui/core';

import { ExpandedSection } from './ExpandedSection';

export const SoftwareFeatures = ({ softwareFeatures, accentColor }) => {
  const {
    features,
    expandedListHeader,
    expandedListSubheader,
    associatedProducts,
  } = softwareFeatures;

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Collapse in={expanded}>
        <ExpandedSection
          header={expandedListHeader}
          subheader={expandedListSubheader}
          features={features.slice(18, 32)}
          associatedProducts={associatedProducts}
        />
      </Collapse>
    </>
  );
};
