import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import { IconSvg } from '../IconSvg';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8rem 0 0 0',
  },
  pinkHighlight: {
    color: '#D10066',
  },
  subHeader: {
    fontWeight: 400,
    fontSize: '1.25rem',
    marginTop: '15px',
  },
  scrollUl: {
    listStyle: 'none',
    padding: 0,
    margin: '1.5rem 0 0 0',
    '& li': {
      marginBottom: '1.5rem',
      display: 'flex',
      alignItems: 'flex-start',
      fontWeight: 500,
    },
    '& strong': {
      color: theme.workwaveBlue,
      fontWeight: 600,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
}));

export const PricingFeature = ({ feature }) => {
  const classes = useStyles();

  const renderFill = (index) => {
    switch (index) {
      case 0:
        return '#002D5C';
      case 1:
        return '#FFCB46';
      case 2:
        return '#002D5C';
      case 3:
        return '#0380B6';
      case 4:
        return '#D10066';
      case 5:
        return '#55B448';
      default:
        return '#000000';
    }
  };

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12} md={6}>
        <PortableText
          content={feature._rawAlternativeBodyHeader || []}
          serializers={{
            h2: ({ children }) => (
              <Typography
                variant='h2'
                color='primary'
                className={classes.header}
              >
                {children}
              </Typography>
            ),
            strong: ({ children }) => (
              <span className={classes.pinkHighlight}>{children}</span>
            ),
          }}
        />
        <Typography variant='h5' className={classes.subHeader}>
          {feature.bodySubHeader}
        </Typography>
        <PortableText
          content={feature._rawBodyCopy || []}
          serializers={{
            normal: ({ children }) => (
              <Typography variant='body1' className={classes.paragraph}>
                {children}
              </Typography>
            ),
            ul: ({ children }) => {
              return (
                <ul className={classes.scrollUl}>
                  {React.Children.map(children, (child, i) =>
                    React.isValidElement(child)
                      ? React.cloneElement(child, {
                          key: i,
                          children: (
                            <>
                              <span className={classes.icon}>
                                <IconSvg fill={renderFill(i)} />
                              </span>
                              <Typography
                                variant='body1'
                                className={classes.bodyContent}
                              >
                                {child.props.children}
                              </Typography>
                            </>
                          ),
                        })
                      : null
                  )}
                </ul>
              );
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GatsbyImage
          image={feature.image.featureImage?.asset?.gatsbyImageData}
          alt='chart'
          className={classes.sectionImage}
        />
      </Grid>
    </Grid>
  );
};
